import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageTitle from '../../components/page_title'
import BlogPostPreview from '../../components/blog_post_preview'
import PageContents from '../../components/page_contents'
import * as style from '../../styles/blog.module.css'

export default function Blog({ data }) {
  const blogPosts = data.allMarkdownRemark.nodes

  return (
    <Layout showFooter={true}>
      <Seo
        title='Blog'
        description='View recent blog posts from the Linden Law Group.'
      />

      <PageContents>
        <PageTitle>What We're Thinking</PageTitle>

        <div className={`${style.main} wide_content`}>
          <div className={style.headline}>Recent Blog Posts</div>

          {blogPosts.length === 0 ? (
            <span className={style.placeholder}>No posts yet</span>
          ) : (
            blogPosts.map(post => (
              <BlogPostPreview
                key={post.fields.slug}
                frontmatter={post.frontmatter}
                excerpt={post.excerpt}
                slug={post.fields.slug}
              />
            ))
          )}

          <Link
            to={'all'}
            className={style.post_link}
            style={{ alignSelf: 'var(--alignment)' }}
          >
            View All
          </Link>
        </div>
      </PageContents>
    </Layout>
  )
}

export const BlogListQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        html
        frontmatter {
          title
          author
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
        excerpt(pruneLength: 400)
      }
    }
  }
`
